import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {

    return (
        <footer className="footer">
            <div className="footer__sub-section">
                <div className="footer__wrapper wrapper">
                    <div className="footer__container">
                        <div className="footer__content-container">
                            &#169; { new Date().getFullYear() } The Hexagon
                        </div>
                        {/* <div className="footer__content-container">
                            <Link className="footer__link" to="/terms-and-conditions">Terms &amp; Conditions</Link>
                        </div>
                        <div className="footer__content-container">
                            <Link className="footer__link" to="/privacy-policy">Privacy Policy</Link>
                        </div> */}
                        <div className="footer__content-container">
                            <Link className="footer__link" to="/cookie-policy">Cookie Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
