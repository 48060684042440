import React from 'react'
import { ApolloProvider } from '@apollo/client';
import { client } from './client';

import CookiePolicy from './src/components/Cookie/CookiePolicy'
import Layout from './src/components/Layout/Layout'

import './src/styles/toolkit.scss'

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={ client }>
        <Layout>
            { element }
            <CookiePolicy />
        </Layout>
    </ApolloProvider>
)