import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const URI = process.env.API_URL ? `${process.env.API_URL}/graphql` : 'http://localhost:1337/graphql';

export const client = new ApolloClient({
	link: new HttpLink({
		uri: URI,
		fetch,
	}),
	cache: new InMemoryCache()
});